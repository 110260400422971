<template>
  <b-input-group class="gap-none">
    <b-form-input
      v-model="date"
      type="text"
      placeholder="YYYY-MM-DD"
      autocomplete="off"
      :state="state"
    ></b-form-input>
    <b-input-group-append>
      <b-form-datepicker
        v-model="date"
        v-bind="{
          'right': true,
          'button-only': true,
          'start-weekday': 1,
          locale: 'ru',
          size: 'sm',
          labelHelp: '',
          'label-no-date-selected': 'Дата не выбрана'
        }"
      ></b-form-datepicker>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
export default {
  name: 'DateSelect',

  props: {
    dateValue: {
      type: [String, Number],
      default: '',
    },
    state: {
      type: [Boolean, Number],
      default: null,
    },
  },
  data() {
    return {
      date: '',
    }
  },
  watch: {
    dateValue: {
      handler(val) {
        this.date = val ? this.$dayjs(val).format('YYYY-MM-DD') : ''
      },
    },
    date: {
      handler(val) {
        this.$emit('update:date', val)
      },
    },
  },
}
</script>
