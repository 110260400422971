var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-input-group',{staticClass:"gap-none"},[_c('b-form-input',{attrs:{"type":"text","placeholder":"YYYY-MM-DD","autocomplete":"off","state":_vm.state},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('b-input-group-append',[_c('b-form-datepicker',_vm._b({model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'b-form-datepicker',{
        'right': true,
        'button-only': true,
        'start-weekday': 1,
        locale: 'ru',
        size: 'sm',
        labelHelp: '',
        'label-no-date-selected': 'Дата не выбрана'
      },false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }