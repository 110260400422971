<template>
  <section>

    <app-card
      @admit="isEdit ? onEdit({ ...$route.params }) : onAdd()"
      @decline="onCansel"
    >
      <template #subtitle>
        <h4>Паспортные данные</h4>
      </template>
      <template #body>
        <div class="col-12 col-lg-6 mb-2 mb-lg-0">
          <label for="staff-name">ФИО сотрудника</label>
          <b-form-input
            ref="full_name"
            id="staff-name"
            v-model="form.full_name"
            class="mb-1"
            :state="validation.full_name"
          ></b-form-input>

          <label for="staff-passport_serie">Серия</label>
          <b-form-input
            id="staff-passport_serie"
            v-model="form.passport_serie"
            class="mb-1"
          ></b-form-input>

          <label for="staff-passport_number">Номер</label>
          <b-form-input
            id="staff-passport_number"
            v-model="form.passport_number"
            class="mb-1"
          ></b-form-input>

          <label for="staff-passport_issued_date">Дата выдачи</label>
          <date-select
            id="staff-passport_issued_date"
            :date-value="form.passport_issued_date"
            @update:date="setDate"
            class="mb-1"
          ></date-select>

          <label for="staff-passport_issued_by">Кем выдан</label>
          <b-form-input
            id="staff-passport_issued_by"
            v-model="form.passport_issued_by"
            class="mb-1"
          ></b-form-input>

          <label for="staff-passport_department_code">Код подразделения</label>
          <b-form-input
            id="staff-passport_department_code"
            v-model="form.passport_department_code"
            class="mb-1"
          ></b-form-input>

          <label for="staff-birth_date">Дата рождения</label>
          <date-select
            id="staff-birth_date"
            :date-value="form.birth_date"
            @update:date="setBirthDate"
            class="mb-1"
          ></date-select>

          <label for="staff-birth_place">Место рождения</label>
          <b-form-input
            id="staff-birth_place"
            v-model="form.birth_place"
            class="mb-1"
          ></b-form-input>

          <label for="staff-registration_place">Прописка</label>
          <b-form-input
            id="staff-registration_place"
            v-model="form.registration_place"
            class="mb-1"
          ></b-form-input>

          <!-- Паспорт Лицевая сторона-->
          <label for="item-date">Фото лицевой стороны паспорта</label>
          <b-form-file
            ref="passport_photo_1"
            v-model="form.passport_photo_1_file"
            :state="validation.passport_photo_1"
            @input="onAddPassportPhotoOne"
            class="mb-1"
            placeholder="Выберите или перетащите файл..."
          ></b-form-file>
          <div class="container mb-2">
            <div class="row gm-2">
              <template v-if="!loading">
                  <div class="col-6 mb-1 mb-lg-0">
                    <img
                      v-if="form.passport_photo_1"
                      :src="`${BASE_URL}${form.passport_photo_1}`"
                      class="img-thumbnail"
                      @click.stop="() => {
                        activeImgUrl = `${BASE_URL}${form.passport_photo_1}`
                        modal.image.visible = true
                      }"
                    >
                  </div>
              </template>
              <template v-else>
                <div class="d-flex justify-content-center">
                  <div
                    class="spinner-border text-secondary mauto"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <!-- Паспорт Лицевая сторона-->
          <!-- Паспорт Прописка -->
          <label for="item-date">Фото страницы паспорта с пропиской</label>
          <b-form-file
            ref="passport_photo_2"
            v-model="form.passport_photo_2_file"
            @input="onAddPassportPhotoTwo"
            class="mb-1"
            placeholder="Выберите или перетащите файл..."
          ></b-form-file>

          <div class="container">
            <div class="row gm-2">
              <template v-if="!loading">
                  <div class="col-6 mb-1 mb-lg-0">
                    <img
                      v-if="form.passport_photo_2"
                      :src="`${BASE_URL}${form.passport_photo_2}`"
                      class="img-thumbnail"
                      @click.stop="() => {
                        activeImgUrl = `${BASE_URL}${form.passport_photo_2}`
                        modal.image.visible = true
                      }"
                    >
                  </div>
              </template>
              <template v-else>
                <div class="d-flex justify-content-center">
                  <div
                    class="spinner-border text-secondary mauto"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <!-- Паспорт Прописка -->
        </div>

        <div class="col-12 col-lg-6">
          <label for="staff-phone">Телефон</label>
          <b-form-input
            ref="phone"
            id="staff-phone"
            v-mask="phoneMask"
            v-model="form.phone"
            class="mb-1 form-control"
            :state="validation.phone"
          ></b-form-input>

          <template v-if="passwordIsVisible">
            <label for="staff-password">Пароль</label>
            <div class="d-flex position-relative app-staff-password">
              <b-form-input
                id="staff-password"
                ref="password"
                :type="passwordMode"
                :visible="true"
                v-model="form.password"
                class="mb-1"
                :state="validation.password"
              ></b-form-input>
              <feather-icon
                class="app-password-icon"
                @click="passwordMode === 'text' ? passwordMode = 'password' : passwordMode = 'text'"
                :icon="passwordMode === 'text' ? 'EyeIcon' : 'EyeOffIcon'"
                size="24"
              />
            </div>
          </template>

          <label for="item-role">Роль</label>
          <b-form-select
            ref="role"
            id="item-role"
            v-model="form.role"
            :options="roleOptions"
            class="mb-1"
            :state="validation.role"
          ></b-form-select>

          <template v-if="form.role === 'courier'">
            <label for="item-color">Цвет</label>
            <section class="app-colorpicker-group">
              <div class="d-flex">
                <b-form-select
                  ref="color"
                  id="item-courier-color"
                  v-model="form.color"
                  :options="courierColors"
                  class="mb-1 pl-4"
                  :state="validation.color"
                ></b-form-select>
                <button
                  class="app-colorpicker-button"
                  :style="`background-color: ${form.color}`"
                ></button>
              </div>
            </section>
          </template>

          <label for="item-created_date">Дата регистрации</label>
          <date-select
            _ref="created_date"
            id="item-created_date"
            :date-value="form.created_date"
            @update:date="setCreatedDate"
            class="mb-1"
            :state="validation.created_date"
          ></date-select>

          <label for="item-city_id">Город</label>
          <b-form-select
            ref="city_id"
            id="item-city_id"
            v-model="form.city_id"
            :options="cityOptions"
            class="mb-1"
            :state="validation.city_id"
          ></b-form-select>
        </div>
      </template>
    </app-card>
    <!-- Modal Img -->
    <common-dialog
      v-bind="{
        value: modal.image.visible,
        hideHeader: true,
        hideFooter: true,
        size: 'lg',
        modalClass: 'app-modal-img',
      }"
      @confirm="() => modal.image.visible = false"
    >
      <template v-if="modal.image.visible">
        <div><img :src="activeImgUrl" /></div>
      </template>
    </common-dialog>
    <!-- Modal Img -->
  </section>
</template>

<script>
import Card from '@/components/common/pages/Card';
import DateSelect from '@/components/common/filters/DateSelect'
import { mapState, mapActions, mapMutations } from 'vuex'
import { StaffNameSpace, StaffActionTypes } from '@/store/modules/staff/types';
import { FileNameSpace, FileActionTypes } from '@/store/modules/file/types';
import { RoleNameSpace, RoleActionTypes } from '@/store/modules/role/types';
import { CityNameSpace, CityActionTypes } from '@/store/modules/city/types';
import { RootMutationTypes } from '@/store/types';
import { AuthNameSpace, RoleCollection } from '@/store/modules/auth/types';
import { throttle } from 'lodash';
import { duration, BASE_URL, phoneMask } from '@/config';
import { colors } from '@/config/staff/index';
// import { Chrome } from 'vue-color';
import { phoneToMaskView } from '@/utils/phone-format';
import clickOutside from '@/directive/click-outside';

export default {
  name: 'StuffItem',
  components: {
    'app-card': Card,
    DateSelect,
    // 'chrome-picker': Chrome,
    'CommonDialog': () => import('@/components/common/common-dialog.vue'),
  },
  directives: {
    clickOutside,
  },
  data() {
    return {
      form: {
        full_name: '',
        passport_serie: '',
        passport_number: '',
        passport_issued_date: '',
        passport_issued_by: '',
        passport_department_code: '',
        birth_date: '',
        birth_place: '',
        registration_place: '',
        passport_photo: [],
        phone: '',
        password: '',
        color: '',
        role: '',
        city_id: '',
        created_date: '',
        passport_photo_1: '',
        passport_photo_2: '',
      },
      validation: {
        full_name: null,
        phone: null,
        password: null,
        role: null,
        color: null,
        city_id: null,
        created_date: null,
        passport_photo: null,
      },
      modal: {
        image: { visible: false },
      },
      showPicker: false,
      BASE_URL,
      activeImgUrl: '',
      colors,
      phoneMask,
      isEdit: false,
      passwordMode: 'password',
    }
  },
  computed: {
    ...mapState({
      loading: 'loaded',
    }),
    ...mapState(AuthNameSpace, {
      userData: 'userData',
    }),
    ...mapState(StaffNameSpace, {
      staff: 'staff',
    }),
    ...mapState(FileNameSpace, {
      files: 'files',
      file: 'file',
    }),
    ...mapState(RoleNameSpace, {
      roles: 'roles',
    }),
    ...mapState(CityNameSpace, {
      cities: 'cities',
    }),
    ...mapState({
      responseMessageType: 'type',
    }),
    userRole() {
      return this.userData.role
    },
    roleOptions() {
      return this.roles.map(role => {
        return { text: role.role, value: role.role }
      })
    },
    cityOptions() {
      return this.cities.filter(city => city.status > 0 ).map(city => {
        return { text: city.name, value: city.id }
      })
    },
    courierColors() {
      return this.colors
    },
    phoneSerialized() {
      return this.form.phone.replace(/\D/g, '')
    },
    passwordIsVisible() {
      if (this.isEdit && this.userData.role !== 'admin') {
        return false
      }
      return true
    },
    hasPermission() {
      return !(this.userData.role === RoleCollection.florist || this.userData.role === RoleCollection.courier)
    },
  },

  async beforeMount() {
    await this.loadCityCollection({ page: 1, page_size: 50 })
    await this.loadRoleCollection()
    await this.editInit()
  },
  methods: {
    ...mapMutations(RootMutationTypes, {
      [RootMutationTypes.SetErrorMessage]: RootMutationTypes.SetErrorMessage,
      [RootMutationTypes.SetLoadedState]: RootMutationTypes.SetLoadedState,
    }),
    ...mapActions(StaffNameSpace, {
      [StaffActionTypes.CreateStaff]: StaffActionTypes.CreateStaff,
      [StaffActionTypes.LoadStaff]: StaffActionTypes.LoadStaff,
      [StaffActionTypes.UpdateStaff]: StaffActionTypes.UpdateStaff,
    }),
    ...mapActions(FileNameSpace, {
      [FileActionTypes.LoadFile]: FileActionTypes.LoadFile,
      [FileActionTypes.LoadFiles]: FileActionTypes.LoadFiles,
    }),
    ...mapActions(RoleNameSpace, {
      [RoleActionTypes.LoadRoleCollection]: RoleActionTypes.LoadRoleCollection,
    }),
    ...mapActions(CityNameSpace, {
      [CityActionTypes.LoadCityCollection]: CityActionTypes.LoadCityCollection,
    }),
    loadStaff: throttle(async function({ id }) {
      await this[StaffActionTypes.LoadStaff]({ id })
    }, duration),
    loadRoleCollection: throttle(async function() {
      await this[RoleActionTypes.LoadRoleCollection]()
    }, duration),
    loadCityCollection: throttle(async function({ page, page_size }) {
      await this[CityActionTypes.LoadCityCollection]({ page, page_size })
    }, duration),

    async onAddFile({ file }) {
      const formData = new FormData()
      formData.append(`files[0]`, file)

      this[RootMutationTypes.SetLoadedState]({ loaded: true })
      await this[FileActionTypes.LoadFiles]({ form_data: formData })
      this[RootMutationTypes.SetLoadedState]({ loaded: false })
    },

    async onAddPassportPhotoOne() {
      await this.onAddFile({ file: this.form.passport_photo_1_file })
      this.form.passport_photo_1 = this.files[0].path
    },
    async onAddPassportPhotoTwo() {
      await this.onAddFile({ file: this.form.passport_photo_2_file })
      this.form.passport_photo_2 = this.files[0].path
    },

    async onAdd() {
      if (this.onValidate()) {
        console.log(this.dateToTimestamp())
        const { passport_issued_date, birth_date, created_at } = this.dateToTimestamp()
        await this[StaffActionTypes.CreateStaff]({
          ...this.form,
          color: this.form.color.hex ? this.form.color.hex : this.form.color,
          phone: this.phoneSerialized,
          passport_issued_date,
          birth_date,
          created_at,
        })

        if (this.responseMessageType !== 'error') this.backHome()
      }
    },

    async onEdit({ id }) {
      if (this.onValidate()) {
        const { passport_issued_date, birth_date, created_at } = this.dateToTimestamp()
        await this[StaffActionTypes.UpdateStaff]({
          id,
          ...this.form,
          color: this.form.color,
          phone: this.phoneSerialized,
          passport_issued_date,
          birth_date,
          created_at,
        },
        )
      }
    },
    async editInit() {
      if (this.$route.params.id !== 'new') {
        this.isEdit = true
        await this.loadStaff({ ...this.$route.params })
        this.form = { ...this.staff }
        this.form.passport_photo = [
          { path: this.staff.passport_photo_1 },
          { path: this.staff.passport_photo_2 },
        ]
        this.form.color = ''
        if (this.staff.role === 'courier') {
          this.form.color = this.staff.color
        }

        this.form.created_date = this.staff.created_at * 1000
        this.form.passport_issued_date = this.staff.passport_issued_date * 1000
        this.form.birth_date = this.staff.birth_date * 1000
        const city = this.cities.find(item => {
          return (item.name === this.staff.city)
        })
        this.form.city_id = city.id
        this.form.phone = this.phoneToMaskView({ phone: this.staff.phone })
      }
    },
    onCansel() {
      this.backHome()
    },
    backHome() {
      this.$router.push('/base-of-staff')
    },
    setDate(date) {
      this.form.passport_issued_date = date
    },
    dateToTimestamp() {
      const passport_issued_date = this.$dayjs(this.form.passport_issued_date).unix()
      const birth_date = this.$dayjs(this.form.birth_date).unix()
      const created_date = this.$dayjs(this.form.created_date).unix()
      const created_at = this.$dayjs(this.form.created_date).unix()

      return { passport_issued_date, birth_date, created_date, created_at }
    },
    setBirthDate(date) {
      this.form.birth_date = date
    },
    setCreatedDate(date) {
      this.form.created_date = date
    },
    hideColorPicker() {
      this.showPicker = !this.showPicker
    },
    phoneToMaskView({ phone }) {
      return phoneToMaskView({ phone })
    },
    onValidate() {
      ['full_name', 'phone', 'role', 'city_id'].forEach(item => {
        if (typeof this.$refs[item].value) {
          if (!this.$refs[item].value) {
            this.validation[item] = false
          } else {
            this.validation[item] = null
          }
        }
      })

      if (!this.isEdit) {
        if (!this.$refs['password'].value) {
          this.validation['password'] = false
        } else {
          this.validation['password'] = null
        }
      }

      if (this.form.role === 'courier') {
        this.validation.color = null
        if (!this.form.color) this.validation.color = false
        else this.validation.color = null
      }

      if (!this.form.created_date) this.validation.created_date = false
      else this.validation.created_date = null

      if ((this.form.passport_photo_1 && !this.form.passport_photo_2) || (!this.form.passport_photo_1 && this.form.passport_photo_2)) {
        this.validation.passport_photo = false
        this[RootMutationTypes.SetErrorMessage]({ message: 'Загрузите второе фото', type: 'error' })
      } else {
        this.validation.passport_photo = null
      }

      const result = Object.values(this.validation).includes(false)
      if (result) return false
      else return true
    },

  },
};
</script>

<style lang="scss" scoped>
.vc-chrome {
    position: absolute;
    z-index: 1000;
    left: 40px;
    top: -250px;
}
.app-colorpicker-group {
    position: relative;
}
.app-colorpicker-button {
    position: absolute;
    border-radius: 5px;
    outline: none;
    border: none;
    width: 32px;
    height: 32px;
    top: 3px;
    left: 8px;
}
.app-password-icon {
    position: absolute;
    right: 10px;
    top: 7px;
}
.app-staff-password {
  .was-validated .form-control:invalid, .form-control.is-invalid {
    background-position-x: 92% !important;
  }
}
</style>
